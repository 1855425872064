import React, { Component, Fragment} from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import clsx from "clsx";
import { StripeProvider, Elements } from "react-stripe-elements";
import { isEmpty, tokenHeader } from "../../utils/Utils";
import { Container, Box, Grid, Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavigateBefore as NavigateBeforeIcon, Block as BlockIcon, Payment as PaymentIcon, Edit as EditIcon, Info as InfoIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon, Cancel as CancelIcon, Help as HelpIcon, OpenInNew} from '@material-ui/icons';
import { SubscriptionDetail as useStyles } from "./Styles";
import Paydue from "./Paydue";
import PaymentComplete from "./PaymentComplete";
import Licenses from "./Licenses";
import ChangePayment from "./ChangePayment";
import apiRequest from "../../utils/apiRequest";
import { Backdrop, RenderHeader, RenderMessage, RenderDialog, RenderFeatureButton, RenderDisplaySection, RenderSectionTitleWithFeature, Typography, Button, IconLabelButton, IconButton } from "../UI/Core";

class SubscriptionDetail extends Component {
  static displayName = SubscriptionDetail.name;
  _isMounted = false;
  constructor(props) {
    super(props);
    let isCorp = (window.location.href.indexOf("corporate") > -1) ? true : false;
    this.state = {
      parentPath: (isCorp) ? "/manage/corporate" : "/manage/subscriptions",
      showLicenses: false,
      errors: {},
      token: {},
      message: {},
      stripeSubscriptionId: props.match.params.stripeSubscriptionId || '',
      invoices: {},
      latestPayment: {},
      paymentMethod: {},
      subscription: {},
      plans: [],
      planPaging: {},
      plan: {},
      payments: [],
      paymentPaging: {},
      items:100,
      // paymentStatus: ['all', 'open', 'paid', 'incompleted', 'unpaid'],
      currFilter: 'all',
      anchorEl: null,
      showInvoice: false,
      showCompleted: false,
      showPayment: false,
      addNewPayment:false,
      isCorp: isCorp,
      currPage: '',
      paymentResult: {/*
        "stripeInvoiceId": "in_1HRatdEwLysLwiwKQrdKaKfV",
        "subscriptionStripeStatus": "active",
        "invoiceStripeStatus": "paid",
        "paymentIntentStripeStatus": "succeeded",
        "stripeSubscriptionId": "sub_I1eCvIbJ72hShC",
        "stripeInvoiceNumber": "577D2D70-0014"
      */},
      paymentIntent: {},
      selectedPaymentId: '',
      stripeApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLEKEY,
      status: ['Trialing', 'Active', 'NotStarted', 'PastDue', 'Incomplete', 'IncompleteExpired', 'UnPaid', 'Canceled', 'Suspended', 'Inactive', 'Unknown'],
      statusType: {
        warning: ['PastDue', 'Incomplete', 'Inactive'],
        valid: ['Trialing', 'Active', 'NotStarted', 'Pending'],
        invalid: ['UnPaid', 'Canceled', 'Suspended'],
        error: ['Unknown']
      }
    };
    // subscription status: trialing, active, incomplete, incomplete_expired, past_due, canceled, unpaid
    //this.getPlans = this.getPlans.bind(this);
    this.unlisten = null;
    this.getSubscription = this.getSubscription.bind(this);
    this.getPayment = this.getPayment.bind(this);
    this.stopRenewal = this.stopRenewal.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showPage = this.showPage.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.forwardPage = this.forwardPage.bind(this);
    this.backwardPage = this.backwardPage.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const token = await tokenHeader();
    const { stripeSubscriptionId } = this.props.match.params;
    const { paymentResult } = this.state;
    const path = this.props.location.pathname;

    if (path.indexOf("paydue/change-payment") > -1) {
      this.setState({ showPayment: true });
    } else if (path.indexOf("paydue/complete") > -1 && isEmpty(paymentResult)) {
      this.forwardPage('showInvoice', 'replace');
      this.setState({ showInvoice: true });
    }else if (path.indexOf("paydue/add-payment") > -1) {
      this.setState({ showInvoice: true, addNewPayment:true });
    }else if (path.indexOf("paydue") > -1) {
      this.setState({ showInvoice: true });
    }

    if (stripeSubscriptionId) {
      this.setState({ token: token, stripeSubscriptionId: stripeSubscriptionId });
      //await this.getPlans();
      await this.getSubscription();
      await this.getPayment();
    } else {
      window.location.href = this.state.parentPath;
    }
    this.unlisten = this.props.history.listen((location, action) => {
      const { paymentResult} = this.state;
      //console.log("on route change", location.pathname, action);
      //let paths = location.pathname.split('/');

      if ((location.pathname.indexOf("subscriptions/paydue/change-payment") > -1)) {
        this.showPage('showPayment');
      }else if ((location.pathname.indexOf("subscriptions/paydue/add-payment") > -1)) {
        this.setState({ addNewPayment:true });
        //this.showPage('showPayment');
      }else if ((location.pathname.indexOf("subscriptions/paydue/change-payment") > -1)) {
        //this.setState('showPayment');
        this.showPage('showPayment');
      } else if ((location.pathname.indexOf("subscriptions/paydue/complete") > -1)) {
        //console.log(paymentResult);
        if (isEmpty(paymentResult))
          this.forwardPage('showInvoice', 'replace');
        else
          this.showPage('showCompleted');
      } else if ((location.pathname.indexOf("subscriptions/paydue/") > -1)) {
        //console.log('paydue show');
        this.showPage('showInvoice');
      } else if ((location.pathname.indexOf("subscriptions") > -1)) {
        this.showPage('');
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.unlisten)
      this.unlisten();
  }
  async getPayment() {
    const { items } = this.state;
    try {
      const res = await apiRequest.paymentMethod.list({limit:items});
      if (this._isMounted) {
        this.setState({
          payments: res.data.results,
          paymentPaging: res.data.paging
        });
      }

    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        //403: Forbidden //404: No such user
        this.setState({
          message: { badRequestError: true }
        });
      } else if (err.response.status === 500) {
        this.setState({
          message: { internalServerError: true }
        });
      }
    }
  }

  // async getPlans() {
  //   try {//, {params: { limit: this.state.items }
  //     //axios.get(URL, { params:{}, headers: { 'Authorization': AuthStr } })
  //     const res = await apiRequest.plan.list({ type: ["Private", "Public"], status: ['Active', 'Inactive'] });
  //     if (res.status === 200) {
  //       if (this._isMounted)
  //         this.setState({ plans: res.data.results, planPaging: res.data.paging });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
  //       if (this._isMounted)
  //         this.setState({ message: { badRequestError: true } });
  //     } else if (err.response.status === 500) {
  //       if (this._isMounted)
  //         this.setState({ message: { internalServerError: true } });
  //     }
  //   }
  // }

  async getSubscription() {
    const { stripeSubscriptionId} = this.state;
      try {
      const res = await apiRequest.subscription.detail(stripeSubscriptionId);
      //const res = await axios.get('/api/v1/subscriptions/detail/' + stripeSubscriptionId, { headers: _token });
      //console.log(res);
      if (res.status === 200) {
        //const { stripePlanId } = res.data.result.subscription;
        //let plan = plans.find((o) => o.stripePlanId === stripePlanId);
        //console.log(stripePlanId, plans, plan, res.data.result);
        if (this._isMounted)
          this.setState({ ...res.data.result });
      }
    } catch (err) {
      if (err.response.status === 404) {
        // this.setState({ message: { notFoundError: true } });
        window.location.href = "/404";
      } else if (err.response.status === 500) {
        if (this._isMounted)
          this.setState({ message: { internalServerError: true } });
      } else {
        if (this._isMounted)
          this.setState({ message: { badRequestError: true } });
      }
    }
  }

  async stopRenewal() {
    let _this = this;
    // code for stop renewal....
    try {
      await apiRequest.subscription.delete({ 'subscriptionId': this.state.stripeSubscriptionId });
      setTimeout(() => {
        if (this._isMounted)
          _this.getSubscription();
      }, 2000);
      this.setState({
        message: { stopRenewalSuccess: true }
      });
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 || err.response.status === 401 || err.response.status === 403) {
        this.setState({ message: { badRequestError: true } });
      } else if (err.response.status === 500) {
        this.setState({ message: { internalServerError: true } });
      }
    }
  }

  handleFilterClick = (open, selected) => event => {
    if (open) {
      this.setState({ anchorEl: event.currentTarget });
    }
    else if (selected) {
      this.setState({ anchorEl: null, currFilter: selected });
    }
    else {
      this.setState({ anchorEl: null });
    }
  }

  handleClose() {
    this.setState({ message: {} });
  }

  handleNotFound() {
    //window.location.href = '/manage/subscriptions' + window.location.search;
    window.location.href = this.state.parentPath + window.location.search;
  }

  async showPage(page) {
    //console.log(page);
    if (this._isMounted) {
      if (page === 'showInvoice') { // show paydue
        this.setState({ showCompleted: false, showPayment: false, showInvoice: true, currPage: 'showInvoice',addNewPayment:false, paymentResult: {} });
      } else if (page === 'showCompleted') { // finish payment
        this.setState({ showCompleted: true, showPayment: false, showInvoice: false, currPage: 'showCompleted',addNewPayment:false })
      } else if (page === 'showPayment') { // show change payment
        this.setState({ showCompleted: false, showPayment: true, showInvoice: false, currPage: 'showPayment', addNewPayment:false })
      } else if (page === 'isLicense') {
        this.setState({ showLicenses: true, currPage: 'isLicense',addNewPayment:false  });
      } else if (page === '') {
        await this.getSubscription();
        if (this._isMounted)
          this.setState({ showCompleted: false, showPayment: false, showInvoice: false, addNewPayment:false, currPage: '', paymentResult: {} });
      }
    }
  }

  forwardPage(page, flag) {
    const { parentPath, stripeSubscriptionId } = this.state;
    //console.log('forward: ',flag,page, stripeSubscriptionId);
    if (page === 'showPayment')
      this.props.history.push({ pathname: `${parentPath}/paydue/change-payment/${stripeSubscriptionId}` });
    else if (page === 'showInvoice') {
      if (flag && flag === 'replace')
        this.props.history.replace({ pathname: `${parentPath}/paydue/${stripeSubscriptionId}` });
      else
        this.props.history.push({ pathname: `${parentPath}/paydue/${stripeSubscriptionId}` });
    } else if (page === 'showCompleted')
      this.props.history.push({ pathname: `${parentPath}/paydue/complete/${stripeSubscriptionId}` });
    else
      this.props.history.push({ pathname: `${parentPath}/${stripeSubscriptionId}` });

  }

  backwardPage(page) {
    //console.log('backward',page);
    const { parentPath, stripeSubscriptionId, } = this.state;
    let _this = this;
    if (page === 'showPayment') {
      this.props.history.replace({ pathname: `${parentPath}/${stripeSubscriptionId}` });
    } else if (page === 'showInvoice') {
      setTimeout(() => {
        if (_this._isMounted)
          _this.getSubscription(_this.state.token);
      }, 2000);
      this.props.history.replace({ pathname: `${parentPath}/paydue/${stripeSubscriptionId}` })
    } else {
      this.setState({ ...this.state, [page]: false });
      this.props.history.replace({ pathname: `${parentPath}/${stripeSubscriptionId}` })
    }

  }

  handleComplete(e) {
    //console.log(e);
    //let _this = this;
    if (e.result) {
      this.setState({ paymentResult: e.result, paymentIntent: e.paymentIntent, selectedPaymentId: e.selectedPaymentId });
      this.forwardPage('showCompleted');
    }
  }

  renderStatus(status, cancelAtPeriodEnd) {
    const { t, classes } = this.props;
    const renderStatus = {
      PastDue: {
        icon: <WarningIcon className={clsx(classes.statusIcon, classes.warning)} />,
        text: t('subscription:past_due'),
        color: "warning"
      },
      Incomplete: {
        icon: <CancelIcon className={clsx(classes.statusIcon, classes.warning)} />,
        text: t('subscription:incomplete'),
        color: "warning"
      },
      Inactive: {
        icon: <InfoIcon className={clsx(classes.statusIcon, classes.info)} />,
        text: t('subscription:inactive'),
        color: "info"
      },
      Trialing: {
        icon: <CheckCircleIcon className={clsx(classes.statusIcon, classes.success)} />,
        text: t('subscription:trialing'),
        color: "success"
      },
      Active: {
        icon: <CheckCircleIcon className={clsx(classes.statusIcon, classes.success)} />,
        text: t('subscription:active'),
        color: "success"
      },
      NotStarted: {
        icon: <InfoIcon className={clsx(classes.statusIcon, classes.info)} />,
        text: t('subscription:not_started'),
        color: "info"
      },
      IncompleteExpired: {
        icon: <CancelIcon className={clsx(classes.statusIcon, classes.error)} />,
        text: t('subscription:invalid'),
        color: "error"
      },
      UnPaid: {
        icon: <CancelIcon className={clsx(classes.statusIcon, classes.error)} />,
        text: t('subscription:unpaid'),
        color: "error"
      },
      Canceled: {
        icon: <CancelIcon className={clsx(classes.statusIcon, classes.error)} />,
        text: t('subscription:cancelled'),
        color: "error"
      },
      Suspended: {
        icon: <CancelIcon className={clsx(classes.statusIcon, classes.error)} />,
        text: t('subscription:suspended'),
        color: "error"
      },
      Unknown: {
        icon: <HelpIcon className={clsx(classes.statusIcon, classes.error)} />,
        text: t('subscription:unknown'),
        color: "error"
      },
      Pending: {
        icon: <WarningIcon className={clsx(classes.statusIcon, classes.warning)} />,
        text: t('subscription:pending'),
        color: "warning"
      }
    }

    return (
      <Fragment>
        {renderStatus[status].icon}

        <Typography variant="body1" component="span" color={renderStatus[status].color}>{renderStatus[status].text}</Typography>
      </Fragment>
    )
  }

  render() {
    const { t, classes } = this.props;
    let { message, showInvoice, showPayment, stripeApiKey, showCompleted, paymentResult, addNewPayment, isCorp, showLicenses, parentPath } = this.state;
    const { paymentIntent, invoices, paymentMethod, subscription, payments, latestPayment, currFilter, stripeSubscriptionId, selectedPaymentId, statusType } = this.state;
    const warningSubscription = statusType.warning.indexOf(subscription.status) !== -1;
    const validSubscription = statusType.valid.indexOf(subscription.status) !== -1;
    const invalidSubscription = statusType.invalid.indexOf(subscription.status) !== -1;
    //const errorSubscription = statusType.error.indexOf(subscription.status) !== -1;

    let featureButtonData;
    //console.log(invoices, latestInvoice, paymentMethod, subscription);
    //console.log('subscription: ', subscription, showInvoice);
    //console.log(plan);
    //console.log('message',message)
    // page data
    const messageData = [
      {
        open: message.badRequestError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:bad_request_error"),
        children: t("errors:bad_request_error_content")
      },
      {
        open: message.internalServerError,
        onClose: this.handleClose,
        severity: "error",
        title: t("errors:internal_server_error"),
        children: t("errors:internal_server_error_content")
      },
      {
        open: message.stopRenewalSuccess,
        onClose: this.handleClose,
        severity: "success",
        children: t("subscription:stop_renewal_success")
      },
      {
        open: message.notFoundError,
        onClose: this.handleNotFound,
        severity: "error",
        title: t("errors:subscription_not_found"),
        children: t("errors:subscription_not_found_caption")
      },
    ]

    const dialogData = [
      {
        open: message.confirmStopRenewal,
        onClose: this.handleClose,
        onConfirm: this.stopRenewal,
        title: t("subscription:confirm_stop_renewal"),
        children: t("subscription:confirm_stop_renewal_content")
      }
    ]
    //console.log(showInvoice, showCompleted, showPayment, addNewPayment);
    if (showLicenses) {
      return (
        <Licenses subscription={subscription} />
      );
    } else if (!isEmpty(subscription)) {
      if (showPayment) {
        return (
          <ChangePayment
            history={this.props.history}
            addNewPayment={addNewPayment}
            onCancel={() => this.backwardPage('showPayment')}
            onComplete={this.handleComplete}
            payments={payments}
            stripeSubscriptionId={stripeSubscriptionId}
            //plan={plan}
            paymentMethod={paymentMethod}
            invoices={invoices.results}
            subscription={subscription} />
        );
      } else if (showInvoice) {
        return (
          <StripeProvider apiKey={stripeApiKey}>
            <Elements>
              <Paydue
                history={this.props.history}
                addNewPayment={addNewPayment}
                onCancel={() => this.backwardPage('showPayment')}
                onComplete={this.handleComplete}
                onReloadPayment={this.getPayment}
                payments={payments}
                stripeSubscriptionId={stripeSubscriptionId}
                //plan={plan}
                latestPayment={latestPayment}
                paymentMethod={paymentMethod}
                invoices={invoices.results}
                subscription={subscription} />
            </Elements>
          </StripeProvider>
        );
      } else if (showCompleted && !isEmpty(paymentResult)) {
        return (
          <PaymentComplete
            onCancel={(flag) => this.backwardPage(flag)}
            payments={payments}
            // stripeSubscriptionId={stripeSubscriptionId}
            //plan={plan}
            selectedPaymentId={selectedPaymentId}
            latestPayment={latestPayment}
            paymentMethod={paymentMethod}
            paymentResult={paymentResult}
            paymentIntent={paymentIntent}
            invoices={invoices.results}
            subscription={subscription} />
        );
      } else if ((!showInvoice && !showCompleted) || isEmpty(paymentResult)) {
        // page data
        const headerData = {
          headerBefore: <IconButton edge="start" component={Link} to={parentPath} className={classes.navigateBeforeRoot}><NavigateBeforeIcon className={classes.navigateBeforeIcon} /></IconButton>,
            header: `${subscription.productName} ${(subscription.planName !== undefined && subscription.planName !== null && subscription.planName !== "") ? ` - ${ subscription.planName }` : ""}`
        }

        featureButtonData = (isCorp) ?
        [(subscription.status === "Inactive" || validSubscription) && 
          <IconLabelButton startIcon={<EditIcon />} component={Link} to={'/manage/licenses/' + stripeSubscriptionId}>{t("subscription:manage_licenses")}</IconLabelButton>,
        
          (subscription.status === "Inactive" || validSubscription) && 
          <IconLabelButton startIcon={<OpenInNew/>} aria-label="dashboard.stripe.com" onClick={() => window.open(`https://dashboard.stripe.com/subscriptions/${subscription.stripeSubscriptionId}`)}>{t("subscription:manage_subscription")}</IconLabelButton>
        ] : [
            (subscription.status === "Inactive" || validSubscription) && !subscription.cancelAtPeriodEnd &&
            <IconLabelButton startIcon={<BlockIcon />} onClick={() => this.setState({ message: { confirmStopRenewal: true } })}>{t("subscription:stop_renewal")}</IconLabelButton>,

            (warningSubscription || validSubscription) && !subscription.cancelAtPeriodEnd &&
            <IconLabelButton startIcon={<PaymentIcon />} onClick={() => this.forwardPage('showPayment')}>{t("subscription:change_payment_method")}</IconLabelButton>
          ];

        const detailData = [
          {
            title: t('subscription:status'),
            valueBase: this.renderStatus(subscription.status, subscription.cancelAtPeriodEnd)
          },
          {
            title: t('subscription:creation_date'),
            value: moment(subscription.createdAt).format("ll")
          },
          ((warningSubscription && subscription.status !== "Incomplete") || validSubscription) && {
            title: t('subscription:period'),
            value: moment(subscription.currentPeriodStart).format("ll") + " - " + moment(subscription.currentPeriodEnd).format("ll")
          },
          (subscription.cancelAtPeriodEnd || (invalidSubscription && (subscription.status !== "Invalid" || subscription.status !== "Unknown"))) && {
            title: t('subscription:end_date'),
            value: subscription.status === "Canceled" && !subscription.cancelAtPeriodEnd ? moment(subscription.currentPeriodStart).format("ll") : moment(subscription.currentPeriodEnd).format("ll")
          },
          (subscription.status !== "Incomplete" && subscription.status !== "Trialing" && subscription.status !== "Pending" && !subscription.cancelAtPeriodEnd && !invalidSubscription) && {
            title: t('subscription:renewal_date'),
            value: subscription.status === "PastDue" ? moment(subscription.currentPeriodStart).format("ll") : moment(subscription.currentPeriodEnd).format("ll")
          },
          (subscription.status !== "Incomplete" && subscription.status !== "Trialing" && subscription.status !== "Pending" && !subscription.cancelAtPeriodEnd && !invalidSubscription) && {
            title: t('subscription:auto_renewal'),
            value: subscription.cancelAtPeriodEnd ? t('common:no') : t('common:yes') +(subscription.cancelAt ? `(${t('common:until')} ${moment(subscription.cancelAt).format("ll")})` : "")
          },
          ((warningSubscription || validSubscription) && !subscription.cancelAtPeriodEnd) && {
            title: t('subscription:payment_method'),
            value: !subscription.paymentMethodId || Object.keys(paymentMethod || {}).length < 3 ? "(" + t("payment:default_payment") + ")" : `${paymentMethod.brand} **** **** **** ${paymentMethod.last4}`,
            // value: paymentMethod.isDefault ? t("payment:default_payment") : `${paymentMethod.brand} **** **** **** ${paymentMethod.last4}`
          }
        ]

        const invoiceTitleData = {
          header: t("subscription:invoice_history"),
          // feature: <IconLabelButton variant="text" endIcon={<ArrowDropDownIcon />} onClick={this.handleFilterClick(true)} className={classes.filter}>{t("subscription:show")}{t("subscription:" + currFilter)}</IconLabelButton>
        }
        //console.log(invoices);

        return (
          <Container maxWidth="md" className={classes.root}>
            <RenderHeader {...headerData} />
            <RenderMessage data={messageData} />
            <RenderDialog data={dialogData} />
            {/* {latestPayment.invoiceStripeStatus === 'open' &&
              (!isEmpty(latestPayment.paymentError) ?
                <Alert severity="error" classes={{ message: classes.alertMessage }}>
                  <AlertTitle>{t('stripe_errors:' + latestPayment.paymentError.code)}</AlertTitle>
                  {t('stripe_errors:' + latestPayment.paymentError.declineCode)}
                </Alert>
                : <Alert severity="warning" classes={{ message: classes.alertMessage }}>
                  <AlertTitle>{t("payment:payment_fails")}</AlertTitle>
                  {t("payment:payment_fails_caption")}
                </Alert>
              )
            } */}
            <RenderFeatureButton data={featureButtonData} />

            <Box className={classes.sectionRoot}>
              <RenderDisplaySection data={detailData} t={t} />
            </Box>
            {invoices && !isEmpty(invoices.results) &&
              <Box data-cy="invoices" className={classes.sectionRoot}>
                <RenderSectionTitleWithFeature data={invoiceTitleData} />
                {/* <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleFilterClick(false)} className={classes.menu}>
                  {paymentStatus.map((item, key) =>
                    <MenuItem key={key} onClick={this.handleFilterClick(false, item)} className={classes.menuItem}>{t("subscription:" + item)}</MenuItem>
                  )}
                </Menu> */}
                <Grid container className={classes.gridContainer}>
                  {invoices.results.map(item =>
                    // (currFilter === item.stripeStatus || currFilter === 'all') && this.renderInvoice(item)
                    (currFilter === item.stripeStatus || currFilter === 'all') &&
                    <RenderInvoice isCorp={isCorp} showInvoiceMessage={(msg) => this.setState(msg)} onShowPage={() => this.forwardPage('showInvoice')} key={item.id} invoice={item} classes={classes} t={t} subscription={subscription} latestPayment={latestPayment} statusType={statusType} />
                  )}
                </Grid>
              </Box>
            }
          </Container>
        )
      }
    } else if (!isEmpty(message)) {
      return (
        <RenderMessage data={messageData} />
      )
    } else {
      return (
        <Backdrop />
      )
    }
  }
}

function RenderInvoice(props) {
  const { classes, t, invoice, latestPayment, isCorp } = props;
  // const [expanded, setExpanded] = useState(Boolean(invoice.stripeStatus === "open" && (subscription.stripeStatus === 'past_due' || subscription.stripeStatus === 'incomplete'))); // When invoice is "open", default will be expanded
  //const [data, setData] = useState({});
  // const warningSubscription = statusType.warning.indexOf(subscription.status) !== -1;
  // const validSubscription = statusType.valid.indexOf(subscription.status) !== -1;
  // const invalidSubscription = statusType.invalid.indexOf(subscription.status) !== -1;
  // const errorSubscription = statusType.error.indexOf(subscription.status) !== -1;

  //console.log('invoice: ', invoice);
  // const getInvoiceDetail = async () => {
  //   let token = await tokenHeader();
  //   try {
  //     //const res = await axios.get('/api/v1/invoices/' + invoice.id, { headers: token });
  //     const res = await apiRequest.invoices.detail(invoice.id);
  //     if (res.status === 200)
  //       setData(res.data.result);
  //   } catch (err) {
  //     if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
  //       props.showInvoiceMessage({ message: { badRequestError: true } })
  //     } else if (err.response.status === 500) {
  //       props.showInvoiceMessage({ message: { internalServerError: true } })
  //     }
  //   }

  // };

  // const handleExpandClick = async () => {
  //   setExpanded(!expanded);
  // };

  // useEffect(() => {
  //   if (expanded && isEmpty(data)) {
  //     getInvoiceDetail();
  //   }
  // },[expanded])

  const handlePay = (e) => {
    props.onShowPage();
  }

  return (
    <Fragment>
      {invoice.stripeStatus === "paid" ?
        <Grid item key={invoice.id} xs={12} className={classes.cardGrid} component="a" href={invoice.stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
          <Card elevation={0} className={clsx(classes.card, classes.cardHover)}>
            <CardContent className={classes.cardHeader}>
              <Typography
                variant="body1"
                color="success"
                className={classes.invoiceStatus}
              >{t("subscription:" + invoice.stripeStatus)}</Typography>

              <Typography variant="h6" className={classes.invoiceHeader}>{t('payment:invoice_number')}: {invoice.stripeInvoiceNumber}</Typography>
            </CardContent>
          </Card>
        </Grid>
        : invoice.stripeStatus === "open" ?
          <Grid item key={invoice.id} xs={12} onClick={isCorp ? null : handlePay}>
            <Card elevation={0} className={isCorp ? classes.card : clsx(classes.card, classes.cardHover)}>
              <CardContent className={classes.cardHeader}>
                <CardContent className={clsx(classes.invoiceStatus, classes.cardContent)}>
                  {
                    isCorp ? <Typography
                      variant="body1"
                      color="warning"
                      className={classes.invoiceStatus}
                    >{t("subscription:" + invoice.stripeStatus)}</Typography> :
                    <Button color="warning" size="small">{t('payment:pay')}</Button>
                  }
                </CardContent>

                <Typography variant="h6" className={classes.invoiceHeader}>{t('payment:invoice_number')}: {invoice.stripeInvoiceNumber}</Typography>
              </CardContent>
              {!invoice.paid && invoice.stripeInvoiceId === latestPayment.stripeInvoiceId &&
                <Fragment>
                  {/* <CardContent className={classes.cardContent}>
                    {!isEmpty(latestPayment.paymentError) ?
                      <Alert severity="warning" classes={{ message: classes.alertMessage }}>
                        <AlertTitle>{t('stripe_errors:' + latestPayment.paymentError.code)}</AlertTitle>
                        {latestPayment.paymentError.declineCode ? t('stripe_errors:' + latestPayment.paymentError.declineCode) : t('stripe_errors:generic_decline')}
                      </Alert>
                      : <Alert severity="warning" classes={{ message: classes.alertMessage }}>
                        <AlertTitle>{t("payment:payment_fails")}</AlertTitle>
                        {t("payment:payment_fails_caption")}
                      </Alert>
                    }
                  </CardContent> */}

                  {/* {warningSubscription && 
                    <CardContent className={classes.cardContent}>
                      <Button color="warning" onClick={handlePay} size="small" >{t('payment:pay_invoice')}</Button>
                    </CardContent>
                  } */}
                </Fragment>
              }
            </Card>
          </Grid>
          : <Grid item key={invoice.id} xs={12}>
            <Card elevation={0} className={classes.card}>
              <CardContent className={classes.cardHeader}>
                <Typography
                  variant="body1"
                  color={(invoice.stripeStatus === "draft") ? "warning" : "error"}
                  className={classes.invoiceStatus}
                >{t("subscription:" + invoice.stripeStatus)}</Typography>

                <Typography variant="h6" className={classes.invoiceHeader}>{t('payment:invoice_number')}: {invoice.stripeInvoiceNumber}</Typography>
              </CardContent>
            </Card>
          </Grid>
      }
    </Fragment>
  )

}

export default withTranslation(["common", "subscription", "payment", "errors"])(withStyles(useStyles)(SubscriptionDetail));